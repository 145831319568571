.container__contact {
    background-color: var(--backgroung--primary);
    height: 100vh;
    min-height: 100vh;
    position: relative;
}

.container__contact--title {
    color: var(--text-color);
    font-size: 32px;
    margin: 0 auto;
    padding-top: 6rem;
    width: 80%;
}

.container__contact--line {
    margin-left: 11rem;
    width: 8%;
}

.container__contact--introduction {
    color: var(--text-color);
    font-size: 22px;
    font-weight: 300;
    margin: 0 auto;
    padding-top: 2rem;
    width: 80%;
}

.container__contact--info {
    display: flex;
    color: var(--text-color);
    font-size: 26px;
    font-weight: 300;
    justify-content: space-around;
    margin: 0 auto;
    padding: 4rem 0rem;
    width: 80%;
}

address>p {
    font-size: 30px;
    font-weight: 400;
}

p>span {
    color: var(--contrast-area);
    font-weight: bold;
}

.container__contact--email {
    color: var(--text-color);
    font-size: 22px;
    text-decoration: none;
}

.container__contact__form {
    display: flex;
    flex-direction: column;
    width: 450px;
}

.container__contact__form>div {
    display: flex;
    justify-content: space-between;
}

.container__form__input {
    background-color: var(--backgroung--secondary);
    border: none;
    border-radius: .8rem;
    box-shadow: 0 0 1.08333rem rgb(0 0 0 / 20%);
    color: var(--text-color);
    padding: .8rem 1rem;
    width: 41%;
}

.container__form__input--invalidEmail {
    color: var(--span--failed);
    font-size: 14px;
    padding-top: .8rem;
}

.container__form__input--email {
    background-color: var(--backgroung--secondary);
    border: none;
    border-radius: .8rem;
    box-shadow: 0 0 2.08333rem rgb(0 0 0 / 50%);
    color: var(--text-color);
    padding: .8rem 1rem;
    margin-top: 1rem;
}

.container__form__input--textarea {
    background-color: var(--backgroung--secondary);
    border: none;
    border-radius: .8rem;
    box-shadow: 0 0 2.08333rem rgb(0 0 0 / 50%);
    color: var(--text-color);
    margin-top: 1rem;
    padding: .7rem 1rem;
}

::placeholder {
    color: var(--text-color);
    opacity: 1;
}

*:focus::placeholder {
    color: transparent;
}

*:focus {
    outline-style: none;
}

.container__form__input--btn {
    align-self: flex-end;
    background-color: var(--contrast-area);
    border: none;
    border-radius: 1.6rem;
    color: var(--text-color);
    cursor: pointer;
    font-size: 18px;
    margin-top: 1.5rem;
    padding: .7rem 1.5rem;
    transition: 1s all;
    width: 140.04px;
}

.container__form__input--btn:hover {
    background-color: var(--btn-hover);
}

.container__form--emailResultMessage {
    background-color: rgba(36, 36, 36, 0.2);
    box-shadow: 0 0 2.08333rem rgb(0 0 0 / 50%);
    border-radius: 1.6rem;
    font-size: 18px;
    line-height: 1.6rem;
    padding: 1rem;
    margin-top: 1.5rem;
}

.emailSucceed {
    border: 2px solid var(--span--succeed);
}

.emailFailed {
    border: 2px solid var(--span--failed);
}

@media screen and (max-width:768px) {
    .container__contact {
        height: 100%;
        padding-bottom: 1.5rem;
    }

    .container__contact--title {
        padding-top: 7rem;
        width: 85%;
    }

    .container__contact--line {
        margin-left: 3.5rem;
        width: 30%;
    }

    .container__contact--introduction {
        line-height: 2rem;
        width: 85%;
    }

    .container__contact--info {
        flex-direction: column;
        padding: 2rem 0 3rem 0;
        width: 85%;
    }

    .container__contact__form {
        width: 100%;
    }

    .container__form__input {
        width: 90%;
    }

    .container__contact__form>div {
        flex-direction: column;
        height: 5.7rem;
        margin-top: 2rem;
    }

    .container__form__input--btn {
        width: 100%;
    }
}

#submit_button.loading::after {
    animation: spin 0.8s linear infinite;
    border: 2px solid var(--backgroung--primary);
    border-radius: 50%;
    border-top: 2px solid #fff;
    content: '';
    display: inline-block;
    height: 12px;
    width: 12px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }