html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

body {
	line-height: 1;
}

:root {
  --backgroung--primary: #3a3d41 ;
  --backgroung--secondary: #74757b;
  --backgroung--technologies-icons: #F2F4F4;
  --btn-hover: #724C68;
  --contrast-area: #e59ad1;
  --span--failed: #DF5B52;
  --span--succeed: #B2DFB7;
  --text-color: #ffffff;
  --work-Sans: 'Work Sans', sans-serif;
  --white: #ffffff;
}

html {
  box-sizing: border-box;
  font-family: var(--work-Sans);
}