.header {
    /* box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5); */
    display: flex;
    flex-direction: row;
    height: 4rem;
    justify-content: space-between;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: 3s ease;
    width: 100%;
    z-index: 999;
}

.hearder--title {
    align-self: center;
    color: var(--text-color);
    font-size: 18px;
    margin-left: 3rem;
}

.header__navigation {
    align-self: center;
    font-weight: 300;
    margin-right: 3rem;
}

.header__anchor {
    color: var(--text-color);
    margin-left: 1rem;
    text-decoration: none;
}

@media screen and (max-width:768px) {
    .header {
        flex-direction: row-reverse;
        margin: 0rem auto;
        width: 100%;
    }

    .hearder--title {
        margin-right: 2rem;
        margin-top: 1.5rem;
    }
}