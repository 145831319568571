.container__about {
    align-items: center;
    background-color: var(--backgroung--secondary);
    height: 100vh;
    position: relative;
}

.container__about--title {
    color: var(--text-color);
    font-size: 32px;
    margin: 0 auto;
    padding-top: 6rem;
    width: 80%;
}

.container__about--line {
    margin-left: 12rem;
    width: 5%;
}

.container__about--content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;
    margin: 2rem auto 2rem auto;
    width: 80%;
}

.description__about {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    color: var(--text-color);
    font-size: 19px;
    font-weight: 300;
    line-height: 2rem;
    margin-right: 3rem;
}

.contrast {
    color: var(--contrast-area);
}

.scroll {
    /* box-shadow: 0 0 2.08333rem rgb(0 0 0 / 50%); */
    /* border-radius: 20px; */
    /* padding-block: 1rem; */
    overflow: hidden;
}

.scroll__container {
    display: flex;
    gap: 5.625rem;
    width: max-content;
    animation: scrollInfinito 20s linear infinite;
}

@keyframes scrollInfinito {
    from {
        /* transform: translateX(0); */
        translate: 0;
    }

    to {
        /* transform: translateX(-100%); */
        translate: calc(-50% - 5px);
    }
}

@media screen and (max-width:768px) {
    .container__about {
        height: 100%;
        padding-bottom: 2rem;
    }

    .container__about--title {
        width: 85%;
    }

    .container__about--line {
        margin-left: 4rem;
        width: 21%;
    }

    .container__about--content {
        flex-direction: column;
        margin: 1.5rem auto;
        width: 85%;
    }

    .description__about {
        line-height: 1.8rem;
        margin-right: 0rem;
        text-align: start;
        width: 100%;
    }

    .description__about>p {
        padding: 0rem;
    }

    .scroll {
        margin-top: 2.5rem;
    }

    .scroll__container {
        gap: 2rem;
    }
}