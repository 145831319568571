.container__portfolio {
    background-color: var(--backgroung--secondary);
    height: auto;
    position: relative;
    padding-bottom: 2rem;
}

.container__portfolio--title {
    color: var(--text-color);
    font-size: 32px;
    margin: 0 auto;
    padding-top: 6rem;
    width: 80%;
}

.container__portfolio--line {
    margin-left: 12rem;
    width: 10.5%;
}

.container__portfolio__cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 1.5rem auto 0 auto;
    max-width: 1252px;
    padding: 2rem 0;
    row-gap: 2rem;
    width: 80%;
}

@media screen and (max-width:768px) {
    .container__portfolio--line {
        margin-left: 4rem;
        width: 44%;
    }
}