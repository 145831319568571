.container__resume {
    align-items: center;
    background-color: var(--backgroung--primary);
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: relative;
}

.container__resume--title {
    color: var(--text-color);
    font-size: 32px;
    margin: 0 auto;
    padding-top: 6rem;
    width: 80%;
}

.container__resume--line {
    margin-left: 12rem;
    width: 6.5%;
}

.container__resume--btn-dowloadCV {
    background-color: var(--contrast-area);
    border: none;
    border-radius: 1.6rem;
    color: var(--text-color);
    cursor: pointer;
    font-size: 18px;
    padding: 1rem;
    right: 0;
    text-align: center;
    text-decoration: none;
    transition: 1s all;
    width: 20%;
}

.container__resume--btn-dowloadCV:hover {
    background-color: var(--btn-hover);
    transform: scale(1.02);
}

@media screen and (max-width:768px) {
    .container__resume {
        height: 100%;
        padding: 4rem 0rem;
    }

    .container__resume--title {
        padding-top: 4rem;
    }
    
    .container__resume--line {
        margin-left: 4rem;
        width: 28%;
    }

    .container__resume--btn-dowloadCV {
        width: 50%;
    }

}