.footer {
    background-color: rgba(36,36,36,0);
    bottom: 0;
    display: flex;
    flex-direction: row;
    height: 4rem;
    justify-content: space-between;
    left: 0;
    position: fixed;
    right: 0;
    text-align: right;
    vertical-align: middle;
    width: 100%;
}

.container--copyright {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-left: 3rem;
}

.footer--icon {
    height: 24px;
    margin-right: 3rem;
    padding: 1rem 0;
    width: 24px;
}

.footer--text {
    color: var(--text-color);
    font-weight: 300;
    margin-right: 1rem;
}

.icon8 {
    color: var(--text-color);
    font-size: 14px;
    font-weight: 200;
    text-decoration: none;
}

@media screen and (max-width:768px) {
    .footer {
        margin: 0 auto;
        width: 85%;
    }
    .container--copyright {
        margin-left: 0rem;
    }
    .footer--icon {
        margin-right: .2rem;
    }
    .footer--text {
        margin-right: .3rem;
    }
    #linkedin {
        margin-right: 1rem;
    }
}