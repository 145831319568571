.container__pagination {
    margin-top: 10rem;
    position: fixed;
    right: 3rem;
    z-index: 1;
}

.pagination__list--item {
    color: var(--text-color);
    font-size: 2rem;
    margin-top: 2.5rem;
}

@media screen and (max-width:768px) {
    .container__pagination {
        right: .5rem;

    }
}