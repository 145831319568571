.container {
    background-color: var(--backgroung--primary);
}

.container__home {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    min-height: 100vh;
    margin-inline: auto;
    width: 80%;
}

.container__home--content {
    width: 50%;
}

.container__home--image {
    width: 50%;
}

.content--text {
    text-align: start;
    color: var(--text-color);
    font-size: 3rem;
    line-height: 4rem;
    font-weight: 200;
    padding: 1.5rem;
}

.content--text--title {
    color: var(--contrast-area);
    font-weight: 600;
}

.home--img {
    width: 500px;
}

@media screen and (max-width:768px) {
    .container__home {
        flex-direction: column-reverse;
        justify-content: center;
    }

    .content--text {
        font-size: 1.8rem;
        line-height: 3rem;
    }

    .container__home--content {
        width: 100%;
    }
    
    .container__home--image {
        width: fit-content;
    }

    .home--img {
        margin: 3.5rem 0;
        width: 300px;
    }
}