.container__cardPortfolio {
    align-items: flex-start;
    background-color: var(--backgroung--primary);
    border-radius: 1.8rem;
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    height: 355px;
    justify-content: space-between;
    width: 333px; 
}

.container__cardPortfolio--title {
    font-size: 18px;
    font-weight: 600;
    padding: 1rem 1rem 0 1rem;
}

.container__cardPortfolio--description {
    font-weight: 300;
    line-height: 1rem;
    padding-inline: 1rem;
}

.container__cardPortfolio--technologies {
    font-weight: 400;
    padding-inline: 1rem;
}

.container__cardPortfolio--bottom {
    display: flex;
    flex-direction: column;
    width: inherit;
}

.container__cardPortfolio--img {
    width: -webkit-fill-available;
    max-height: 200px;
}

.container__cardPortfolio--links {
    display: flex;
    width: inherit;
}

.cardPortfolio--anchor-btn{
    background-color: var(--contrast-area);
    color: var(--text-color);
    padding: .8rem .6rem;
    text-align: center;
    text-decoration: none;
    width: 50%;
}

.left {
    border-radius: 0rem 0rem 0rem 1.8rem;
} 

.right {
    border-radius: 0rem 0rem 1.8rem 0rem;
}

.isDisabled {
    pointer-events: none;
    opacity: 60%;
}

.cardPortfolio--anchor-btn:hover {
    background-color: var(--btn-hover);
}
